import img from "./assets/404.svg";

export default function Error404() {
    return (
        <section className="antialiased text-slate-500 bg-black">
            <div className="bg-graph-circuit-slate-100/[0.04] flex flex-col h-screen justify-center items-center">
                <div className="text-center px-5 text-white relative max-w-6xl mx-auto">
                    <img src={img} className="w-full mx-auto" />
                    <h1 className="text-4xl font-bold uppercase">
                        Not Found
                    </h1>
                    <h6 className="text-xl italic mb-5">
                        Lagi cari apa ne?
                    </h6>
                    <a href="/"
                        className="backdrop-blur-sm bg-white/30 text-white font-bold opacity-80 py-2 px-4 rounded-full border-2 shadow-sm mt-4">
                        BACK
                    </a>
                </div>
            </div>
        </section>
    )
}
