import { BrowserView, MobileView } from "react-device-detect";
import WebDesktop from "./WebDesktop";
import WebMobile from "./WebMobile";

export default function App() {
  return (
    <>
      <BrowserView>
        <WebDesktop />
      </BrowserView>
      <MobileView>
        <WebMobile />
      </MobileView>
    </>
  );
}
