import img from "./assets/hero.svg";

export default function webMobile() {
  return (
    <section className="antialiased text-slate-500 bg-black">
      <div className="bg-graph-cogs-slate-100/[0.04] flex flex-col h-screen justify-center items-center">
        <div className="text-center px-5 text-white relative max-w-6xl mx-auto">
          <img src={img} className="w-[50%] mx-auto" />
          <h1 className="text-4xl font-bold uppercase">
            Hi! Saya
            <span className="text-blue-700"> AL Gzl</span>
          </h1>
          <h6 className="text-xl italic">
            Saya Seorang Programmer WEB
          </h6>
          <p className="mt-5 mb-10">
            Perkenalkan nama saya <i className="text-purple-500 font-medium">Shofiyullah AL Ghozaly</i> lulusan dari Sarjana Informatika, saat ini saya bekerja sebagai Programmer disalah satu Universitas Swasta di Yogyakarta. Saya kerja sudah lebih dari 3 tahun, adapun aplikasi web yang pernah saya bangun menggunakan framework <i className="text-red-600 font-bold">Laravel</i> dan <i className="text-orange-600 font-bold">React JS</i>.
          </p>
          <a target="_blank" rel="linkedin"
            href="https://www.linkedin.com/in/shofiyullah17/"
            className="backdrop-blur-sm bg-white/30 text-white font-bold opacity-80 py-2 px-4 rounded-full border-2 shadow-sm"
          >
            Portofolio
          </a>
        </div>
      </div>
    </section>
  )
}
