import img from "./assets/hero.svg";

export default function WebDesktop() {
  return (
    <>
      <section className="antialiased text-slate-500 bg-gradient-to-bl from-black/90 via-black to-black/95 scroll-smooth">
        <div className="bg-graph-cogs-slate-100/[0.04] flex flex-col h-screen justify-center items-center">
          <div className="flex items-center justify-between text-white relative max-w-6xl mx-auto">
            <div className="max-w-lg">
              <h1 className="text-4xl font-bold uppercase">
                Hi! Saya
                <span className="text-blue-700"> AL Gzl</span>
              </h1>
              <h6 className="text-xl italic">
                Saya Seorang Programmer WEB
              </h6>
              <p className="mt-5 mb-10">
                Perkenalkan nama saya <i className="text-purple-500 font-medium">Shofiyullah AL Ghozaly</i> lulusan dari Sarjana Informatika, saat ini saya bekerja sebagai Programmer disalah satu Universitas Swasta di Yogyakarta. Saya kerja sudah lebih dari 3 tahun, adapun aplikasi web yang pernah saya bangun menggunakan framework <i className="text-red-600 font-bold">Laravel</i> dan <i className="text-orange-600 font-bold">React JS</i>.
              </p>
              <a target="_blank" rel="linkedin"
                href="https://www.linkedin.com/in/shofiyullah17/"
                className="backdrop-blur-sm bg-white/30 text-white font-bold opacity-80 py-2 px-4 rounded-full border-2 shadow-sm"
              >
                Portofolio
              </a>
            </div>
            <div>
              <img className="w-[50%] mx-auto" src={img} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
