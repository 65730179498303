import { useEffect } from "react";
import { RingLoader } from "react-spinners";
import img from "./assets/mail.svg";


export default function WebMail() {
    useEffect(() => {
        setTimeout(() => {
            window.location = "https://mail.zoho.com";
        }, 3000);
    }, []);

    return (
        <section className="antialiased text-slate-500 bg-black">
            <div className="bg-graph-circuit-slate-100/[0.04] flex flex-col h-screen justify-center items-center">
                <div className="text-center px-5 text-white relative max-w-6xl mx-auto">
                    <img src={img} className="w-full mx-auto" />
                    <h1 className="text-4xl font-bold uppercase">
                        Portal Email
                    </h1>
                    <RingLoader className="mx-auto my-4"
                        color={'#123abc'}
                        loading={true}
                    />
                    <h6 className="text-xl italic">
                        Redirecting...
                    </h6>
                </div>
            </div>
        </section>
    );
}
